import React from 'react';

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"

import { Animated } from "react-animated-css"

const AboutUs = () => {
    return (
        <Layout>
            <Seo title="Om oss" />
            <Header image={true} big={true} text="Om oss" />

            <section className="content">
                <div className="container">
                    <div className="row pt-0">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="p-3 p-lg-5">
                                <h2>Vår historia</h2>
                                <h3>Hur allt började</h3>
                                <p className="lh-base">Med en stark övertygelse att solenergi är den bästa lösningen på klimatkrisen. I en liten lägenhet i Solna utanför Stockholm 2021 satte de syftet som gäller än idag – att leda vägen mot en hållbar framtid med solenergi.</p>
                                <p className="lh-base">Med en stark övertygelse att solenergi är den bästa lösningen på klimatkrisen. I en liten lägenhet i Solna utanför Stockholm 2021 satte de syftet som gäller än idag – att leda vägen mot en hållbar framtid med solenergi.</p>
                                <p className="lh-base">Med en stark övertygelse att solenergi är den bästa lösningen på klimatkrisen. I en liten lägenhet i Solna utanför Stockholm 2021 satte de syftet som gäller än idag – att leda vägen mot en hållbar framtid med solenergi.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <img src="/img/ashraf_qatanani.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="content" style={{ backgroundColor: '#F7F7F7' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <h3>Mod</h3>
                            <p className="lh-base">Vi strävar alltid efter förbättring, efter att bli ännu starkare och mer lättillgängliga. Vi drivs av en positiv framtidssyn och är inte rädda för att sätta höga mål och kämpa för en bättre morgondag, för alla.</p>
                        </div>
                        <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                            <h3>Innovation</h3>
                            <p className="lh-base">Vi är självsäkra i allt vi gör. Med unik expertis och kunskap om potentialen I solenergi, får vi våra kunder att lita på oss och våra idéer – för vi kan vår verksamhet bättre än någon annan.</p>
                        </div>
                        <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                            <h3>Ge kraft</h3>
                            <p className="lh-base">Vi går i fronten, ger samhället nya uppfattningar om förnybar energi och skapar en ny norm för morgondagens energiförbrukning.</p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default AboutUs;
